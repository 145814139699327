<template>
<div>
  <div><org-change @orgChange="orgChange"/></div>

  <div>
    <div class="container">
      <title-cop  :title="(isStreet ? '街道' : '社区' ) + '各问题类型统计'"/>
      <div class="user_status"><type-cop ref="type" :list="labelList" @change="typeChange" /></div>
    </div>

    <div class="container">
      <title-cop  :title="(isStreet ? '街道各社区' : '社区各网格' ) + typeName + '统计'"  :tip="typeName == '问题总数' ? '点击上方问题类型进行切换' : ''" />
      <div class="peple_change"><width-bar v-if="widthBarShow" ref="widthBar" :labelName="valueName" :dataName="typeName" :labelCount="valueList" /></div>
    </div>

  </div>
</div>
</template>

<script>
import orgChange from './components/orgChange.vue'
import SexProgress from './components/sex-progress.vue'
import titleCop from './components/title-cop'
import totalNumber from './components/total-Number'
import UserType from './components/userType.vue'
import widthBar from './components/widthBar.vue'
import lineCop from './components/line-cop.vue'
import stackBar from './components/stackBar.vue'
import typeCop from './components/type-cop.vue'
export default {
  components: {
    titleCop,
    totalNumber,
    SexProgress,
    orgChange,
    widthBar,
    UserType,
    lineCop,
    stackBar,
    typeCop
  },
  data () {
    return {
      orgId: 0,
      isStreet: true,
      widthBarShow: false,
      orgList: [],
      dictId: 0,
      labelList: [],
      valueName: [],
      valueList: [],
      typeName: '问题总数'
    }
  },
  created () {
    this.init()
  },
  methods: {
    orgChange (id, isStreet) {
      this.orgId = id
      this.isStreet = isStreet
      this.type = null
      this.dictId = null
      this.typeName = '问题总数'
      this.$refs.type.reset()
      this.init()
    },
    typeChange (index, id, name) {
      if (id) {
        this.dictId = id
        this.typeName = name
        this.getTypeList()
      } else {
        this.type = null
        this.dictId = null
        this.typeName = '问题总数'
        this.getTypeList()
      }
    },
    init () {
      this.getTypeList()
    },
    getTypeList () {
      this.widthBarShow = false
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/problemCountStreet'),
        method: 'get',
        params: this.$http.adornParams({
          orgId: this.orgId || this.$globalData.userInfo.orgId,
          dictId: this.dictId || null
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.labelList = [{name: '问题总数', value: 0, unit: '个', img: require('@/assets/img/statistics/problem_count.png'), list: []}]
          this.labelList[0].list = data.data.listProblemCount.map(item => {
            this.labelList[0].value += item.labelCount
            return {
              name: item.labelName,
              value: item.labelCount,
              id: item.labelId,
              unit: '个'
            }
          })
          this.valueName = data.data.communityGridCount.sort((a, b) => a.problemCount - b.problemCount).map(item => item.communityGridName)
          this.valueList = data.data.communityGridCount.sort((a, b) => a.problemCount - b.problemCount).map(item => item.problemCount)
          this.widthBarShow = true
          this.$nextTick(() => {
            this.$refs.widthBar.initChartBar()
          })
        } else {
          this.$toast.fail(data.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.topOrgBox {
  padding: 20px 30px;
  .topOrg {
    width: 100%;
    height: 74px;
    text-align: center;
    line-height: 74px;
    margin: 0;
    border-radius: 10px;
    background-color: #fff;
    .pull-icon {
        width: 10px;
    }
  }
}
.container {
  background-color: #fff;
  margin-bottom: 20px;
}
.split_line {
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 2px solid #eee;
}
.user_status {
  min-height: 400px;
}
.user_age {
  height: 500px;
}
</style>
